import { get, writable } from 'svelte/store';

export const darkMode = writable(false);

export enum Theme {
	DARK = 'dark',
	LIGHT = 'light'
}

export const toggleDarkMode = () => {
	darkMode.update((value) => !value);
	// save to local storage
	localStorage.setItem('_c', get(darkMode) ? Theme.DARK : Theme.LIGHT);
	// update uno css
	const uno = document.querySelector('html');
	if (uno) {
		uno.classList.toggle('dark');
	}
};

export const getPreferredTheme = () => {
	if (!window) return Theme.LIGHT;
	// check local storage
	if (localStorage.getItem('_c') === Theme.DARK) return Theme.DARK;
	if (localStorage.getItem('_c') === Theme.LIGHT) return Theme.LIGHT;
	// check for dark mode preference at os level
	if (window.matchMedia('(prefers-color-scheme: dark)').matches) return Theme.DARK;
	if (window.matchMedia('(prefers-color-scheme: light)').matches) return Theme.LIGHT;
	// default to light
	return Theme.LIGHT;
};

export const colorInit = () => {
	// TEMP: disable dark mode
	// const theme = getPreferredTheme();
	// const uno = document.querySelector('html');
	// document.documentElement.style.setProperty(
	// 	'--scrollbar-width',
	// 	window.innerWidth - document.documentElement.clientWidth + 'px'
	// );

	// if (uno) {
	// 	uno.classList.toggle('dark', theme === Theme.DARK);
	// }
	// darkMode.set(theme === Theme.DARK);
	// // update uno css

	// // add event listener for os level dark mode preference
	// window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
	// 	if (e.matches) {
	// 		darkMode.set(true);
	// 	} else {
	// 		darkMode.set(false);
	// 	}
	// });

	// // update local storage
	// localStorage.setItem('_c', theme);

	darkMode.set(false);
};
